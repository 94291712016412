<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64" />
        </v-overlay>
        <v-card
            class="mx-auto"
            max-width="1100"
        >
            <v-col>
                <v-row>
                    <v-col 
                        cols="12"
                        md="12"
                    >
                        <v-col>
                            <v-toolbar
                                color="primary"
                                dark
                                dense
                            >
                                DATOS DE LA COTIZACIÓN
                            </v-toolbar>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-col cols="12" md="5">
                                    <ul>
                                        <li>
                                            <b>Cotización:</b> 
                                            <a 
                                                target="_blank" 
                                                :href="`/crm_cotizacion?sop_id=${quote.sales_opportunity_id}&quote_id=${this.$route.params.quoteId}`"
                                            >
                                                {{ this.$route.params.quoteId }}
                                            </a>
                                        </li>
                                        <li><b>Estado:</b> {{ quote.estado }}</li>
                                        <li>
                                            <b>Opotunidad:</b> 
                                            <a 
                                                target="_blank"
                                                :href="`/oportunidad_form/${quote.sales_opportunity_id}`"
                                            > 
                                                {{ quote.sales_opportunity_id }} 
                                            </a>
                                        </li>
                                        <li><b>Cliente:</b> {{ quote.name }}</li>
                                    </ul>
                                </v-col>
                                <v-col cols="12" md="7">
                                    <v-simple-table dense>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        Orden
                                                    </th>
                                                    <th class="text-left">
                                                        Fecha de creación
                                                    </th>
                                                    <th class="text-left">
                                                        Estado
                                                    </th>
                                                    <th class="text-left">
                                                        Acciones
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(ord, x) in ordenes"
                                                    :key="x"
                                                >
                                                    <td>{{ ord.orden_servicio_id }}</td>
                                                    <td>{{ ord.created_stamp }}</td>
                                                    <td>{{ ord.status }}</td>
                                                    <td >
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn 
                                                                    icon 
                                                                    v-bind="attrs" 
                                                                    v-on="on" 
                                                                    color="success"
                                                                    :to="`/genera-orden-servicio/${$route.params.quoteId}/${ord.orden_servicio_id}`"
                                                                    target="_blank"
                                                                >
                                                                    <v-icon >mdi-eye</v-icon>                                                                    
                                                                </v-btn>
                                                            </template>
                                                            <span>Ver orden</span>
                                                        </v-tooltip>
                                                    </td>   
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-col>
                    <v-col 
                        cols="12"
                        class="pt-0"
                        md="4"
                    >                       
                        <v-col cols="12">
                            <v-toolbar
                                color="primary"
                                dark
                                dense
                            >
                                PACIENTES DE LA COTIZACIÓN
                                <v-divider />
                                <v-icon 
                                    @click="dialogAgregarPaciente"
                                >
                                    mdi-account-multiple-plus
                                </v-icon>
                            </v-toolbar>
                        </v-col>
                        <v-list-item
                            v-for="(p, x) in pacientes"
                            :key="x"
                        >
                            <v-list-item-content>
                                <v-list-item-title style="font-size: 13px; white-space: normal;">
                                    <v-checkbox
                                        v-model="partyIdSelected"
                                        :value="p.party_id"
                                        :label="p.name"
                                        hide-details
                                    />
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col 
                        cols="12"
                        class="pt-0"
                        md="8"
                    >
                        <v-col cols="12">
                            <v-toolbar
                                color="primary"
                                dark
                                dense
                            >
                                SERVICIO DE LA COTIZACIÓN
                            </v-toolbar>
                        </v-col>
                        <v-col>
                            <v-select
                                v-model="servicioSeleccionado"
                                :items="servicios"
                                hide-details="auto"
                                dense
                                outlined
                                label="Servicios"
                                item-text="servicio"
                                item-value="servicio_crm_id"
                                @change="seleccionarServicio"
                            />
                        </v-col>
                        <div v-if="dataServicios.length">
                            <v-list-item
                                v-for="(servs, x) in dataServicios"
                                :key="x"
                            >
                                <v-list-item-content>
                                    <v-list-item-title >
                                        <div class="mb-2">
                                            <b>{{ servs[0].servicio.toUpperCase() }}</b>
                                        </div>
                                    </v-list-item-title>
                                
                                        <v-list-item-subtitle
                                            v-for="(ss, y) in servs"
                                            :key="y"
                                            class="my-2"
                                        > 
                                            <b>{{ ss.sub_servicio }} </b> -  
                                            <input 
                                                v-model="ss.quantity_selected"
                                                type="number"
                                                min="0" 
                                                class="input-quantity-servicio"
                                                :max="ss.quantity" 
                                                :id="`sub_servicio_${ss.servicio_crm_id}_${ss.sub_servicio_crm_id}`"
                                                :disabled="servicioSeleccionado==null"
                                                @keyup="ss.quantity_selected = parseInt(ss.quantity_selected) > parseInt(ss.quantity) ? ss.quantity : ss.quantity_selected"
                                            />
                                            <b>DE {{ ss.quantity }}</b>
                                        </v-list-item-subtitle>                                
                                    <v-divider class="mt-2"></v-divider>
                                </v-list-item-content>
                            </v-list-item>
                        </div>
                        <div v-else>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-alert
                                        border="right"
                                        colored-border
                                        type="error"
                                        elevation="2"
                                        dense
                                    >
                                        No hay sub servicios disponibles
                                    </v-alert>
                                </v-list-item-content>
                            </v-list-item>
                        </div>
                        <v-col 
                            class="text-center" 
                            v-if="dataServicios.length"
                        >
                            <v-btn 
                                color="primary"
                                @click="crearOrdenManualCotizacion"
                            >
                                CREAR ORDEN DE SERVICIO
                            </v-btn>
                        </v-col>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
        <v-dialog 
            v-model="dialogPaciente"
            persistent 
            max-width="700px"
        >
            <v-card>
                <v-col>
                    <FormAsignaPacienteComponent
                        v-if="dialogPaciente"
                        asignaA="QUOTE"
                        :quoteId=$route.params.quoteId
                        :btnClose="true"
                        @close="dialogPaciente=false"
                        @pacientes = setPacientesAsignacion
                    />
                </v-col>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
import Vue from 'vue';

import { mapActions, mapState, mapMutations } from "vuex"
import FormAsignaPacienteComponent from './FormAsignaPacienteComponent.vue'

export default {
    name: 'GeneraOrdenServicioComponent',
    components:{
        FormAsignaPacienteComponent
    },
    data: () => ({
        overlay: false,
        dataServicios:[],
        originalDataServicios:[],
        servicios:[],
        pacientes:[],
        ordenes:[],
        statusesCita:[],
        statusCita: '',
        partyIdSelected:null,
        servicioSeleccionado:null,
        dialogPaciente:false,
        quote:{},
        colorStatus:[
            {status_id:'OS_CITA_CREADA', color:'#ededed'},
            {status_id:'OS_CITA_INICIADA', color:'#f6921e'},
            {status_id:'OS_CITA_COMPLETADA', color:'#37b34a'},
            {status_id:'OS_CITA_CANCELADA', color:'#ff5252'},
        ],
        serviceCrmId: null,
        subServiceCrmId: [],
        partyIdClient:null,
        oportunidadId:null,
        ordenServicioCitaId:null,
    }),
    computed: {

        ...mapState("master", ["loadingTable","tenantId","paramAlertQuestion","titleToolbar",])

    },
    methods:{

        ...mapMutations("master", ["setUrl", "setLoadingTable", "setTitleToolbar"]),

        ...mapActions("master", ["requestApi", "alertNotification"]),

        getDataOrdenDeServicio(){

            this.overlay = true

            this.setUrl(`orden-servicio/${this.$route.params.quoteId}`)
            
            this.requestApi({
                method: "GET",
                data: {
                    quoteId: this.$route.params.quoteId,
                    statusCita: this.statusCita,
                    typeView: 'OBTENER_SERVICIOS_COTIZACION'
                },
            }).then(res => {

                const { dataServicios, servicios, pacientes, quote, ordenes } = res.data
                
                this.dataServicios = dataServicios
                this.originalDataServicios = dataServicios
                this.servicios = servicios
                this.pacientes = pacientes
                this.quote = quote
                this.ordenes = ordenes
                this.overlay = false

            }).catch(err => {

                this.overlay = false

            })
    
        },

        crearOrdenManualCotizacion(){

            if(this.partyIdSelected == null){

                this.alertNotification({
                    param:{
                        title: 'Error',
                        icon: 'error',
                        confirmButtonColor: 'red',
                        html: 'Debe seleccionar al paciente para generar la orden'
                    }
                })

                return false

            }

            let subServicios = this.dataServicios[0].filter(e => e.quantity_selected != '' && e.quantity_selected > 0)

            let serv = this.servicios.find(e => e.servicio_crm_id == this.servicioSeleccionado) 

            subServicios.map(obj => {

                obj.valoracion = serv.valoracion
                return obj

            })

            if(!subServicios.length){

                this.alertNotification({
                    param:{
                        title: 'Error',
                        icon: 'error',
                        confirmButtonColor: 'red',
                        html: 'Debe seleccionar los sub servicios de la orden'
                    }
                })

                return false

            }

            console.log('this.dataServicios',subServicios)
            //return false
            this.overlay = true

            this.setUrl(`orden-servicio`)
            
            this.requestApi({
                method: "POST",
                data: {
                    quoteId: this.$route.params.quoteId,
                    servicioId: this.servicioSeleccionado,
                    pacienteId: this.partyIdSelected,
                    subServicios,
                    typeStore: 'generaOrdenServicioManual'
                },
            }).then(res => {

                console.log(res)

                this.getDataOrdenDeServicio()

                this.alertNotification({
                    param:{
                        html: res.data.res.msg
                    }
                })
              
                this.servicioSeleccionado=null
                this.partyIdSelected =null
                this.seleccionarServicio()

                this.overlay = false

            }).catch(err => {

                console.log(err)
                this.overlay = false

            })

        },

        seleccionarServicio(){
           
            if(this.servicioSeleccionado == null){

                this.dataServicios = this.originalDataServicios.map(ss => {

                    ss.map(ss2 => {

                        Vue.set(ss2, 'quantity_selected','') 

                        return ss2

                    })

                    return ss

                })

            }else{

                this.dataServicios = this.originalDataServicios
                .map(ss => {

                    ss.map(ss2 => {

                        Vue.set(ss2, 'quantity_selected','') 

                        return ss2

                    })

                    return ss

                })
                .filter(e => e[0].servicio_crm_id == this.servicioSeleccionado)
            
            }

        },

        dialogAgregarPaciente(){

            this.dialogPaciente = true

        },

        setPacientesAsignacion(pacientes){

            this.pacientes = pacientes
            this.dialogPaciente = false

        }

    },
    mounted(){

        this.setTitleToolbar("ORDEN DE SERVICIO")

    },
    created(){

        this.getDataOrdenDeServicio()
        console.log('params', this.$route.params)
        //COT95417
    }

}
</script>

<style>
.v-expansion-panel-content__wrap{
    padding: 0!important;
}

.w-100{

    width: 100%;
}

.v-input--selection-controls {
    margin-top: 0px;
    padding-top: 0px;
}

.select-sub-servicio  div.v-select{
    height: 40px;
}


.select-sub-servicio div.v-input__prepend-outer{
    margin: 0!important;
}

.select-sub-servicio .v-text-field.v-text-field--solo .v-input__control {
    min-height: 38px;
    padding: 0;
}

.select-sub-servicio  .v-select__selection{
    white-space: normal
}

.input-quantity-servicio{

    border: 1px solid silver; 
    border-radius: 5px; 
    width: 50px;
    height: 27px;
    text-align: center;
    margin-right: 5px;
}

.input-quantity-servicio:disabled{
    background-color: #ededed;
    border : 1px solid #ededed;
}
</style>